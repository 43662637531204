@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/variables';

@import '../css/variables';
@import '../css/mixins';

.template-puraply {
    .component-jumbotron {
        .frames {
            .frame {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .product-title {
                    margin: 0 auto;
                    text-align: center;
                }

                .text-large {
                    display: block;
                    font-size: 2.5rem;
                    line-height: 1em;
                }

                .text-medium {
                    display: block;
                    font-size: 3rem;
                    line-height: 1.5em;
                }

                .text-small {
                    display: block;
                    font-size: 1.25rem;
                    line-height: 1.25em;
                }
            }
        }
    }
    .checkmark-items {
        .item {
            margin-bottom: 30px;

            .item-icon {
                margin-top: 10px;
                margin-bottom: 10px;
                text-align: center;
            }

            .item-text {
                margin-top: 25px;
                font-family: 'BentonSans-Medium';
                font-size: 1.375rem;
                line-height: 1.1em;
                text-align: center;
            }
        }
    }
    .small-text {
        text-align: left;
        font-size: 1.125rem;
    }
    .icon-plus {
        display: flex;
        justify-content: center;
        display: block;
        height: 80px;
        color: #fdc90e;
        font-size: 9rem;
        line-height: 20px;
        margin-top: 2rem;
        margin-right: 2rem;
        margin-bottom: 2rem !important;
        margin-left: 2rem;
    }
    .block-grey {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $grey-100;
        border-top: 1px solid $grey-500;
    }
    .img-with-label {
        position: relative;

        .label {
            display: inline-block;
            position: absolute;
            bottom: 0;
            padding: 5px;
            width: 70%;
            left: 15%;
            color: $pink;
            z-index: 1;
            background-color: rgba(255, 255, 255, .6);
            font-family: 'BentonSans-Medium';
            font-size: 0.875rem;
            transition: background-color ease-in 0.7s;
            text-align: center;
        }

        &:hover {
            .label {
                background-color: rgba(255, 255, 255, .8);
            }
        }
    }
    .footnotes {
        font-size: 0.875rem !important;
    }
}

/* Mobile */
@include media-breakpoint-down(sm) {
    .template-puraply {
        .component-jumbotron {
            .frames {
                margin-top: 390px;
            }
            .product-logo-mobile {
                display: block;
                position: absolute;
                top: 90px;
                left: 20px;
                width: 200px;
            }
            .product-logo-desktop {
                display: none;

                &.side-by-side {
                    display: block;
                    .product-logo {
                        display: none;
                    }
                    .product-title {
                        .line2 {
                            margin-top: 10px;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .component-jumbotron {
            background-color: #12253c;

            &.awc-via-matrix {
                background: linear-gradient(to bottom, #051c2c 0%, #051c2c 50%, #006b56 99%, #006b56 100%);
            }
        }
        .component-section-copy p {
            font-size: 1.25rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .template-puraply {
        .component-jumbotron {
            .frames {
                .product-logo-mobile {
                    display: none;
                }
                .frame {
                    .product-logo-desktop {
                        display: block;
                        flex-grow: 0;
                        margin-top: 40px;
                        width: 270px;

                        &.side-by-side {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            margin: 0 auto;
                        }

                        .product-logo {
                            width: 450px;
                            height: 400px;
                            margin-top: 120px;
                        }

                        .product-title {
                            margin-left: 100px;
                            margin-top: 100px;
                            height: 400px;
                        }
                    }

                    .product-title {
                        flex-grow: 1;
                        margin: 70px 0 0 0;
                        text-align: left;

                        h2 {
                            letter-spacing: 0.7em !important;
                        }

                        &.product-ppsx {

                            .text-large {
                                padding-left: 40px;
                                font-size: 7rem;
                                letter-spacing: 0.55em !important;
                            }

                            .text-small {
                                padding-left: 0;
                            }
                        }

                        &.product-cygnus-dual {
                            .text-large {
                                padding-left: 0;
                                font-size: 6rem;
                                letter-spacing: 0.3em !important;
                            }

                            .text-small {
                                padding-left: 80px;
                                letter-spacing: 0.375em;
                            }

                            .line1 {
                                padding-left: 150px !important;
                            }
                            .line2 {
                                margin-top: 1em;
                                padding-left: 90px;
                                color: #fff;
                                letter-spacing: 0.275em;
                            }
                        }
                    }

                    .text-large {
                        padding-left: 75px;
                        font-size: 6rem;
                        line-height: 1em;
                    }

                    .text-small {
                        padding-left: 170px;
                        font-size: 2rem;
                        line-height: 1.5em;
                    }
                }
            }

            &.awc-via-matrix {
                .frames {
                    .frame {
                        .product-logo-desktop {
                            .product-logo {
                                img {
                                    max-width: 72%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .checkmark-items {
            display: flex;
            justify-content: space-around;
            flex-direction: row;

            .item {
                flex: 1 1 200px;
                margin-bottom: 0;
            }
        }
        .icon-plus {
            margin-top: 160px;
            margin-left: 20px;
        }
        .product-ppsx {
            min-width: 380px;
        }
        .product-ppmz {
            min-width: 40px;
        }
        .img-with-label {
            position: relative;
            width: 385px;
            margin: 0 30px;

            .label {
                display: inline-block;
                position: absolute;
                bottom: 0;
                padding: 2px;
                width: 70%;
                left: 15%;
                color: $pink;
                z-index: 1;
                background-color: rgba(255, 255, 255, .6);
                font-family: 'BentonSans-Medium';
                font-size: 1rem;
                transition: background-color ease-in 0.7s;
            }

            &:hover {
                .label {
                    background-color: rgba(255, 255, 255, .8);
                }
            }
        }
    }
}
