@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/variables';

@import '../../css/variables';
@import '../../css/mixins';

$time-line-color: #585858;
$time-line-arrow-color: #000f21;
$time-line-tracker-height: 3px;
$time-line-label-top: 45px - ($time-line-tracker-height * 0.5);
$time-line-padding-horizontal-mobile: 45px;
$time-line-item-min-width: 225px;

.component-our-story-timeline {
    margin: 0 auto;
    padding: 0 $time-line-padding-horizontal-mobile;
    overflow: visible;
    max-width: initial;
    width: $time-line-item-min-width + ($time-line-padding-horizontal-mobile * 2);
    .jtimeline-holder {
        padding: 50px 0 300px;
        position: relative;
        overflow: hidden;
        max-width: initial;
        width: $time-line-item-min-width;
    }
    ul.jtimeline-events {
        & > li.jtimeline-event {
            .jtimeline-event-bullet {
                width: 12px;
                height: 12px;
                top: -7.5px;
                left: 55px;
                border-width: 0;
                background-color: $time-line-color;
            }
            .jtimeline-event-label {
                left: initial;
                padding-top: 32.5px;
                height: 200px;
                display: block;
                top: $time-line-label-top;
                text-align: left;
                .milestone-year {
                    font-size: 3.4375rem;
                    line-height: 1;
                    color: $subhead;
                    font-family: 'BentonSansCond-Regular';
                }
                .milestone-description {
                    padding-top: 30px;
                    line-height: 1.25;
                    font-size: 0.95rem;
                }
                &::after {
                    display: none;
                }
            }
            &.is-active,
            &:hover {
                .jtimeline-event-bullet {
                    background-color: $time-line-color;
                }
            }
            &:nth-child(odd) {
                .jtimeline-event-label {
                    top: $time-line-label-top;
                    align-items: initial;
                }
            }
        }
        &::before {
            display: none;
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: $time-line-tracker-height;
            background-color: $time-line-color;
            position: absolute;
            top: -$time-line-tracker-height;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .jtimeline-scroll-left,
    .jtimeline-scroll-right {
        top: 50%;
        transform: translateY(-50%);
        &.is-disabled {
            opacity: 0.5;
        }
        & > .jtimeline-arrow {
            background-color: transparent;
            top: -26.5px;
            width: 20px;
            font-family: 'BentonSans-Book';
            font-size: 4.5rem;
            box-shadow: none;
            color: $time-line-arrow-color;
        }
    }
    .jtimeline-scroll-left {
        left: 0;
    }
    .jtimeline-scroll-right {
        right: 0;
    }
}

$time-line-width-md: $time-line-item-min-width * 2;
@include media-breakpoint-up(md) {
    .component-our-story-timeline {
        width: $time-line-width-md + ($time-line-padding-horizontal-mobile * 2);
        .jtimeline-holder {
            width: $time-line-width-md;
        }
    }
}

$time-line-width-lg: $time-line-item-min-width * 3;
@include media-breakpoint-up(lg) {
    .component-our-story-timeline {
        width: $time-line-width-lg + ($time-line-padding-horizontal-mobile * 2);
        .jtimeline-holder {
            width: $time-line-width-lg;
        }
    }
}

$time-line-padding-horizontal-desktop: 200px;
$time-line-offset-xl: 35px;
@include media-breakpoint-up(xl) {
    .component-our-story-timeline {
        padding: 0 $time-line-padding-horizontal-desktop;
        width: $time-line-width-lg + ($time-line-padding-horizontal-desktop * 2);
        .jtimeline-scroll-left {
            left: $time-line-offset-xl;
        }
        .jtimeline-scroll-right {
            right: $time-line-offset-xl;
        }
    }
}