@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/variables';

@import './variables';
@import './mixins';

.container-puraply-jumbotron {
    padding-top: 0 !important;
    background: none;
}

.container-pagenav {
    .component {
        a {
            display: block;
            padding: 12px 0;
            font-family: 'BentonSans-Bold';
            color: $grey-1000;
            text-decoration: none;
            transition: opacity 0.5s;
            border-top: 1px solid $grey-500;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.container-download {
    .component-download {
        p {
            width: 100%;
            font-family: 'BentonSans-Regular';
            color: $black;
            margin: 0 0 15px 0;
            text-align: center;
            letter-spacing: 0.5px;
            font-size: 1.25em;
            line-height: 1.4em;
        }
    }
}

.container-toast {
    position: fixed;
    width: 100%;
    bottom: -100px;
    background: $toast-bg-color;
    box-shadow: 0 0 12px 0 rgba(2, 1, 1, 0.2);
    z-index: 99999;
    -webkit-transition:all 1s ease;
       -moz-transition:all 1s ease;
        -ms-transition:all 1s ease;
         -o-transition:all 1s ease;
            transition:all 1s ease;
}

@include media-breakpoint-down(md) {
    .container-header {
        background: $main-nav-bg-mobile;
    }

    .container-download {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .container-toast {
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        & > .component {
            min-width: 250px;
            padding-right: 50px;
            font-size: 0.85rem;
        }
    }
}

@include media-breakpoint-up(md) {

    .container,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1400px;
    }

    .container-header {
        position: relative;
        z-index: 1;
        @include gradient(top, $main-nav-bg);
    }

    .container-pagenav {
        border-bottom: 1px solid $grey-400;

        .component {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 70px;
            text-align: center;

            a {
                display: inline-block;
                color: $grey-1000;
                text-decoration: none;
                transition: opacity 0.5s;
                opacity: 1;
                border-top: none;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .container-download {
        padding: 35px 0;
        border-bottom: 1px solid $grey-500;

        .component-download {
            .wrapper {
                div {
                    width: 32%;
                    margin: 0.5%;

                    .button {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .container-main-copy {
        max-width: 1100px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }
}