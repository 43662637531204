@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

.component-preword-ops {
    ul {
        padding-top: 1rem;
        li {
            margin-bottom: 1em;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            img {
                flex-grow: 0;
                flex-shrink: 0;
                width: 75px;
                height: 75px;
                display: block;
                margin-right: 10px;
            }
            div {
                flex-grow: 1;
                flex-shrink: 1;
            }
            span {
                font-family: 'BentonSans-Bold';
            }
        }
    }
}
