@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-disclaimer {
    padding-top: 10px;
    padding-bottom: 0;
    .container {
        text-align: center;
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-disclaimer {
        padding-top: 10px;
        padding-bottom: 0px;
    }
}

@include media-breakpoint-up(lg) {
    .component-disclaimer {
        padding-bottom: 70px;
    }
}